import React from 'react';

import Card from './Card';

const StepContent = ({ children }) => {
  return <div className="p-4 bg-white rounded-lg">{children}</div>;
};

const HelpArticle = ({ title, children, videoSrc = '', videoTitle = '' }) => {
  return (
    <article>
      <Card>
        <Card.Heading as="h1">{title}</Card.Heading>
        <Card.Body>
          <section>
            {videoSrc && (
              <div>
                <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                  <iframe
                    src={videoSrc}
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                    }}
                    title={videoTitle}
                  ></iframe>
                </div>
              </div>
            )}
          </section>
          <section className="pt-4 lg:pt-6">
            <ul style={{ counterReset: 'stepCounter' }}>
              {React.Children.map(children, (child) => (
                <li
                  style={{ counterIncrement: 'stepCounter' }}
                  className="flex flex-col items-center mb-4 last:mb-0 lg:flex-row lg:items-start lg:mb-6"
                >
                  <StepContent>
                    <div className="before:content-[counter(stepCounter)] flex items-center justify-center w-5 h-5 tabular-nums text-2xl lg:text-3xl font-bold lg:w-6 lg:h-6"></div>
                  </StepContent>
                  <div className="w-full mt-4 lg:mt-0 lg:ml-4">{child}</div>
                </li>
              ))}
            </ul>
          </section>
        </Card.Body>
      </Card>
    </article>
  );
};

HelpArticle.StepContent = StepContent;

export default HelpArticle;
