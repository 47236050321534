import React from 'react';
import { graphql } from 'gatsby';

import HelpLayout from '../../../components/HelpLayout';
import HelpArticle from '../../../components/HelpArticle';
import SEO from '../../../components/SEO';

const HowToAddVideoInSquarespacePage = ({ location, data }) => {
  return (
    <HelpLayout location={location}>
      <SEO
        title="How to add video in Squarespace"
        description="Learn how to add a video to your Squarespace page."
      />
      <HelpArticle
        title="How to add video in Squarespace"
        videoTitle="squarespace guide"
        videoSrc={data.site.siteMetadata.squarespaceGuideVideoUrl}
      >
        <HelpArticle.StepContent>
          <p>
            In the <strong>Library</strong> select the video you want to embed
            in your webpage.
          </p>
        </HelpArticle.StepContent>
        <HelpArticle.StepContent>
          <p>
            Click on <strong>Actions</strong> and choose{' '}
            <strong>Get page embed code</strong>. Dialog will pop up and you
            press the <strong>Copy</strong> button. Now you have the embed code
            in your clipboard.
          </p>
        </HelpArticle.StepContent>
        <HelpArticle.StepContent>
          <p>Go to your Squarespace site’s editor.</p>
        </HelpArticle.StepContent>
        <HelpArticle.StepContent>
          <p>
            Start with creating a new element where the video should appear.
            Choose the <strong>Code</strong> element. Alternatively, you can
            also use the <strong>Video</strong> or <strong>Embed</strong>{' '}
            elements.
          </p>
        </HelpArticle.StepContent>
        <HelpArticle.StepContent>
          <p>
            Check the selected mode is <strong>HTML</strong> and paste the code
            in place of the example code. The video should now show on the page.
          </p>
        </HelpArticle.StepContent>
      </HelpArticle>
    </HelpLayout>
  );
};

export const query = graphql`
  query HowToAddVideoInSquarespacePageQuery {
    site {
      siteMetadata {
        squarespaceGuideVideoUrl
      }
    }
  }
`;

export default HowToAddVideoInSquarespacePage;
